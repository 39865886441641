<template>
  <div class="px-3">
    <div class="row mt-2">
      <div class="col-12 mt-5">
        <form class="row g-3">
          <div class="col-12">
            <center>
              <img src="@/assets/logo-ssrs.png" class="img-fluid">
              <div v-if="user">Olá {{ user.name }}!</div>
            </center>
          </div>
          <div class="col-12 d-grid gap-2">
            <button :disabled="button.disabled" type="button" :class="sistema.aberto.class" @click="toggleLanche()">
              <span v-if="button.loading.lanche" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ button.loading.text }}
            </button>
          </div>
          <div class="col-12 d-grid gap-2">
            <button :disabled="button.disabled" type="button" class="btn btn-warning" @click="resetNotification()">
              <span v-if="button.loading.reset" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Resetar tabela
            </button>
          </div>
          <div class="col-12 d-grid gap-2">
            <button :disabled="button.disabled" type="button" class="btn btn-primary" @click="pushToMenu()">
              Ir para lanches & bares
            </button>
          </div>
          <div class="col-12 d-grid gap-2">
            <button :disabled="button.disabled" type="button" class="btn btn-outline-dark" @click="logout()">
              Sair do sistema
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import authInfo from '../services/auth-info';
import authHeader from '../services/auth-header';
import helpersHttp from '../services/helpers-http';
export default {
  data() {
    return {
      user: authInfo(),
      browser: {
        url: process.env.API_URL,
        headers: authHeader(),
      },
      button: {
        disabled: false,
        loading: {
          reset: false,
          lanche: false,
          text: 'Aguarde...'
        },
      },
      sistema: {
        loading: true,
        aberto: {
          lanches: false,
          class: 'btn btn-secondary'
        },
      },
    }
  },
  created() {
    this.getStatusLanche();
  },
  methods: {
    pushToMenu() {
      this.$router.push('/menu');
    },
    resetNotification() {
      this.button.disabled = true;
      this.button.loading.reset = true;
      helpersHttp('reset-notification', 'POST').then((response) => {
        if(response.status && response.status == 'error') {
          this.accessDenied();
        }
        this.button.disabled = false;
        this.button.loading.reset = false;
      }).catch((error) => {
        console.error(error);
        this.$swal('Erro no sistema', error.message);
        this.button.disabled = false;
        this.button.loading.reset = false;
      });
    },
    getStatusLanche() {
      helpersHttp('status-lanches', 'POST').then((response) => {
        if(response.status && response.status == 'error') {
          this.accessDenied();
        }else{
          if(response.data.open){
            this.button.loading.text = 'Fechar lanches';
            this.sistema.aberto.class = 'btn btn-danger';
          } else {
            this.button.loading.text = 'Abrir lanches';
            this.sistema.aberto.class = 'btn btn-success';
          }
        }
      }).catch((error) => {
        console.error(error);
        this.$swal('Erro no sistema', error.message);
      });
    },
    toggleLanche() {
      this.button.disabled = true;
      this.button.loading.lanche = true;
      helpersHttp('toggle-lanches', 'POST').then((response) => {
        if(response.status && response.status == 'error') {
          this.accessDenied();
        }else{
          if(response.data.open){
            this.button.loading.text = 'Fechar lanches';
            this.sistema.aberto.class = 'btn btn-danger';
            this.button.disabled = false;
            this.button.loading.lanche = false;
          } else {
            this.button.loading.text = 'Abrir lanches';
            this.sistema.aberto.class = 'btn btn-success';
            this.button.disabled = false;
            this.button.loading.lanche = false;
          }
        }
      }).catch((error) => {
        console.error(error);
        this.$swal('Erro no sistema', error.message);
      });
    },
    accessDenied(){
      this.$swal('Acesso negado!', 'Você não tem autorização para esta ação.', 'error');
    },
    logout(){
      this.$store.dispatch('logout');
    }
  }
}
</script>
